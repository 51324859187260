/* globals google */
import { h } from 'preact'; /** @jsx h */
import { useEffect, useRef } from 'preact/hooks';
import formatcoords from 'formatcoords';
import UtmObj from 'utm-latlng';
import * as clipboard from 'clipboard-polyfill';

import { RemoteDataStatus, RemoteDataError } from '../utils/remoteData';
import { Icon } from '../Icons.js';
import './LocationMessage.css';

const getErrorText = remoteDataError =>
    remoteDataError.type === RemoteDataError.NotFound
        ? 'Får ikke hentet adresse ved bruk av koordinater.'
        : 'Feil ved henting av adresse';

const copyText = text => {
    clipboard.writeText(text);
};

const LocationAddress = ({ address }) => {
    switch (address.status) {
        case RemoteDataStatus.NotAsked:
            return;
        case RemoteDataStatus.Loading:
            return (
                <div className="locationMessage-address">Loading address</div>
            );

        case RemoteDataStatus.Success:
            return (
                <ul className="locationMessage-address">
                    <li>
                        I nærheten av{' '}
                        {address.data.addressComponents[0].long_name},{' '}
                        {address.data.addressComponents[1].long_name}
                    </li>
                    <li>{address.data.formattedLocation}</li>
                </ul>
            );
        case RemoteDataStatus.Failure:
            return (
                <div className="locationMessage-address">
                    <li>{getErrorText(address.remoteDataError)}</li>
                </div>
            );

        default:
            return <div>Feil RemoteData status {address.status}</div>;
    }
};

const LocationMessage = ({ location, address }) => {
    if (!location || !location.every(it => typeof it === 'number')) {
        return <div> Venter på koordinater fra App </div>;
    }

    const googleMapsSearchUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;

    // DMS = Degrees Minutes Seconds
    const dmsCoords = `${formatcoords(location[0], location[1]).format('DD m', {
        latLonSeparator: ' N, '
    })} Ø`;

    // Converting LatLng to UTM
    const utm = new UtmObj();
    const convertedUtm = utm.convertLatLngToUtm(location[0], location[1], 3);

    const mapRef = useRef();

    useEffect(() => {
        if (
            window.googleMapsReady &&
            location !== undefined &&
            location[0] !== null &&
            mapRef.current !== undefined
        ) {
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: location[0], lng: location[1] },
                zoom: 10,
                fullscreenControl: false
            });

            /* eslint-disable-next-line no-unused-vars */
            const marker = new google.maps.Marker({
                position: { lat: location[0], lng: location[1] },
                map
            });
        }
    }, [location]);

    return (
        <div className="locationMessage-wrapper">
            <div className="locationMessage-map" ref={mapRef} />
            <a
                className="locationMessage-map-button"
                href={`${googleMapsSearchUrl}`}
                rel="noopener noreferrer"
                target="_blank"
            >
                Åpne kart <Icon.ExternalLink />
            </a>
            <div className="locationMessageInfo">
                <div className="locationMessage-coordinates">
                    <div className="locationMessage-coordinates-item">
                        <p>Ø {convertedUtm.Easting}</p>
                        <button
                            className="locationMessage-coordinates-button"
                            onClick={() => copyText(convertedUtm.Easting)}
                        >
                            <Icon.Copy />
                        </button>
                    </div>
                    <div className="locationMessage-coordinates-item">
                        <p>N {convertedUtm.Northing}</p>
                        <button
                            className="locationMessage-coordinates-button"
                            onClick={() => copyText(convertedUtm.Northing)}
                        >
                            <Icon.Copy />
                        </button>
                    </div>
                    <p className="locationMessage-coordinates-utmZone">
                        UTM {convertedUtm.ZoneNumber + convertedUtm.ZoneLetter}
                    </p>
                    <p className="locationMessage-coordinates-dms">
                        {dmsCoords}
                    </p>
                </div>
                {address && <LocationAddress address={address} />}
            </div>
        </div>
    );
};

export default LocationMessage;
