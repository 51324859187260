export const RemoteDataStatus = {
    NotAsked: 'not-asked',
    Loading: 'loading',
    Failure: 'failure',
    Success: 'success'
};

export const RemoteDataError = {
    NotFound: 'not-found',
    ServerError: 'server-error'
};

// Remote data helpers
export const notAsked = () => ({
    status: RemoteDataStatus.NotAsked
});
export const loading = () => ({
    status: RemoteDataStatus.Loading
});
export const failure = remoteDataError => ({
    status: RemoteDataStatus.Failure,
    remoteDataError
});
export const success = data => ({
    status: RemoteDataStatus.Success,
    data
});

// Error helpers
export const notFound = () => ({
    type: RemoteDataError.NotFound
});

export const serverError = reason => ({
    type: RemoteDataError.ServerError,
    reason
});
