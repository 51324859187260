/* globals WebSocket */

export const SocketStatus = {
    NotStarted: 'NotStarted',
    Connecting: 'Connecting',
    Open: 'Open',
    Closing: 'Closing',
    Closed: 'Closed',
    Error: 'Error'
};

export const mapSocketReadyStateToSocketStatus = readyState => {
    switch (readyState) {
        case WebSocket.CONNECTING:
            return SocketStatus.Connecting;

        case WebSocket.OPEN:
            return SocketStatus.Open;

        case WebSocket.CLOSING:
            return SocketStatus.Closing;

        case WebSocket.CLOSED:
            return SocketStatus.Closed;

        default:
            return SocketStatus.NotStarted;
    }
};
