import { h } from 'preact'; /** @jsx h */
import { Icon } from '../Icons';

const ShortInfo = ({ name, phoneNumber, onClick }) => {
    return (
        <button
            className="callerInfo-shortInfo callerInfo-shortInfo--failure"
            onClick={onClick}
        >
            <div className="callerInfo-shortInfo-name">
                {name}
                <Icon.DownArrow />
            </div>
            <div className="callerInfo-shortInfo-number">{phoneNumber}</div>
        </button>
    );
};

export default ShortInfo;
