import { h } from 'preact'; /** @jsx h */

import './ConversationListItem.css';

import { useDuration } from '../utils/useDuration';

const ConversationListItem = ({
    appPhoneNumber,
    id,
    activeConversationId,
    selectConversation,
    name,
    startTime,
    isClosed,
    tables,
    unreadMessages
}) => {
    const { duration } = useDuration(startTime);

    const compClass = 'conversationListItem';

    const buttonActiveClass =
        activeConversationId === id ? ` ${compClass}--active` : '';
    const buttonClosedClass = isClosed ? ` ${compClass}--closed` : '';
    const buttonClass = `${compClass}-button${buttonActiveClass}${buttonClosedClass}`;

    return (
        <li className={compClass} key={id}>
            <button className={buttonClass} onClick={selectConversation}>
                <div>
                    <div className={compClass + '-name'}>
                        {name}{' '}
                        {unreadMessages !== 0 && (
                            <span className={compClass + '-unread'}>
                                {unreadMessages}
                            </span>
                        )}
                    </div>
                    <div className={compClass + '-number'}>
                        {appPhoneNumber}
                    </div>
                </div>
                <div className={compClass + '-time'}>
                    {!isClosed ? (
                        <div>
                            <div>{duration}</div>
                            {tables.length > 0 && (
                                <div className={compClass + '-table'}>
                                    Bord {tables.join(', ')}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>Avsluttet</div>
                    )}
                </div>
            </button>
        </li>
    );
};

export default ConversationListItem;
