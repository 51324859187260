import { h } from 'preact'; /** @jsx h */
import { useState, useRef, useEffect } from 'preact/hooks';
import { sendPhoneNumber, sendCode } from './loginApi';

const trimStr = str => str.replace(/\s/g, '');

const LoginForm = ({
    onSubmitLocal,
    label,
    id,
    maxLength,
    placeholder,
    error,
    btnText,
    disabled,
    selectTable
}) => {
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <form
            className="login-form"
            onSubmit={onSubmitLocal}
            method="post"
            action="/"
        >
            <div className="login-form-inputGroup">
                <label className="login-form-label" htmlFor={id}>
                    {label}
                </label>
                <input
                    type="tel"
                    className="login-form-input"
                    id={id}
                    name={id}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    disabled={disabled}
                    required
                    autoComplete="off"
                    ref={inputRef}
                />
            </div>
            {selectTable && (
                <div className="login-form-inputGroup">
                    <label className="login-form-label" htmlFor="table-select">
                        Velg bord
                    </label>
                    <select name="table" id="table-select" required>
                        <option value="">Velg bord</option>
                        <option value="1">Bord 1</option>
                        <option value="2">Bord 2</option>
                        <option value="3">Bord 3</option>
                        <option value="4">Bord 4</option>
                        <option value="5">Bord 5</option>
                        <option value="6">Bord 6</option>
                    </select>
                </div>
            )}
            {error && <p className="login-form-error">{error}</p>}
            <button className="login-form-button" disabled={disabled}>
                {btnText}
            </button>
        </form>
    );
};

export const AmkLoginPhoneNumber = ({ onSuccess }) => {
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const onSubmitLocal = async e => {
        e.preventDefault();

        setDisabled(true);

        const phoneNumber = trimStr(e.target.phoneNumber.value);

        if (/^[0-9]{8}$/.test(phoneNumber)) {
            const { error } = await sendPhoneNumber(phoneNumber);

            if (error) {
                setError(error);
                setDisabled(false);
            } else {
                onSuccess();
            }
        } else {
            setError('Ugyldig telefonnummer');
            setDisabled(false);
        }
    };

    return (
        <LoginForm
            onSubmitLocal={onSubmitLocal}
            label="Mobilnummer"
            id="phoneNumber"
            maxLength="8"
            error={error}
            btnText="Send"
            placeholder="Ditt mobilnummer"
            disabled={disabled}
        />
    );
};

export const AmkLoginCode = ({ onSuccess }) => {
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const onSubmitLocal = async e => {
        e.preventDefault();

        setDisabled(true);

        const code = trimStr(e.target.code.value);

        if (/^[0-9]{4}$/.test(code)) {
            const tableSelect = e.target['table-select'].value;

            const { error, token, expirationTime } = await sendCode(
                code,
                tableSelect
            );

            if (error) {
                setError(error);
                setDisabled(false);
            } else {
                onSuccess(token, expirationTime);
            }
        } else {
            setError('Ugyldig kode. Minst 4 tegn');
            setDisabled(false);
        }
    };

    return (
        <LoginForm
            onSubmitLocal={onSubmitLocal}
            label="SMS-kode"
            id="code"
            maxLength="4"
            error={error}
            btnText="Logg inn"
            placeholder="Kode mottatt på SMS"
            disabled={disabled}
            selectTable={true}
        />
    );
};
