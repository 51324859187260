import { useState, useEffect, useRef } from 'preact/hooks';

export const calculateDuration = (startTime, endTime) => {
    const durationSeconds = Math.floor((endTime - startTime) / 1000);

    let hours = Math.floor(durationSeconds / 3600);
    let minutes = Math.floor(durationSeconds / 60) % 60;
    let seconds = durationSeconds % 60;

    const hoursInt = hours;
    // Start with 0 if under 2 decimals to always display HH:MM:SS
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');

    if (hoursInt < 1) {
        return minutes + ':' + seconds;
    } else {
        return hours + ':' + minutes + ':' + seconds;
    }
};

export const calculateDurationToText = (startTime, endTime) => {
    const durationSeconds = Math.floor((endTime - startTime) / 1000);

    let hours = Math.floor(durationSeconds / 3600);
    let minutes = Math.floor(durationSeconds / 60) % 60;
    let seconds = durationSeconds % 60;

    const hoursInt = hours;
    // Start with 0 if under 2 decimals to always display HH:MM:SS
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');

    if (hoursInt < 1) {
        return minutes + ' minutt(er) ' + seconds + ' sekunder';
    } else {
        return (
            hours +
            ' time(r) ' +
            minutes +
            ' minutt(er) ' +
            seconds +
            ' sekunder'
        );
    }
};

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const useDuration = startTime => {
    const [duration, setDuration] = useState(
        calculateDuration(startTime, Date.now())
    );

    useInterval(() => {
        setDuration(calculateDuration(startTime, Date.now()));
    }, 1000);

    return { duration };
};
