/* globals fetch */
import { success, failure, loading, serverError } from './remoteData';
import { getToken } from './useStorage';

export const fetchCallerInfo = async (phoneNumber, onStatusChange) => {
    onStatusChange(loading());

    let response;

    try {
        response = await fetch(`/callerinfo/${phoneNumber}`, {
            cache: 'no-cache',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        onStatusChange(failure(serverError(error)));
    }

    let json;

    if (response.ok) {
        try {
            json = await response.json();
        } catch (error) {
            onStatusChange(failure(serverError(error)));
        }

        if (json.error) {
            onStatusChange(failure(json.error));
        } else {
            onStatusChange(success(json));
        }
    } else {
        onStatusChange(failure(serverError('Feil med nummeroppslag')));
    }
};
