import { h } from 'preact'; /** @jsx h */
import './AlertPopup.css';

const Close = ({ sendEndChat, toggleCloseChatPopup }) => (
    <article className="alertPopup-wrapper">
        <h1 className="alertPopup-title">Avslutte samtale?</h1>
        <p className="alertPopup-message-text">
            Ønsker du å lukke chatten for bruker? Samtalen vil bli avbrutt for
            bruker i Hjelp 113 appen.
        </p>
        <div className="alertPopup-buttons">
            <button
                className="alertPopup-button-cancel"
                onClick={() => toggleCloseChatPopup()}
            >
                Avbryt
            </button>
            <button
                onClick={() => {
                    sendEndChat();
                    toggleCloseChatPopup();
                }}
                className="alertPopup-button-confirm"
            >
                Ja, avslutt samtale
            </button>
        </div>
    </article>
);

const Delete = ({
    sendDeleteConversation,
    closeConversation,
    conversationId,
    toggleDeleteChatPopup
}) => (
    <article className="alertPopup-wrapper">
        <h1 className="alertPopup-title"> Slette samtale? </h1>
        <p className="alertPopup-message-text">
            Slettede samtaler må være kopiert og lagret i AMIS. Slettede
            samtaler kan ikke hentes opp igjen. Ønsker du å slette samtalen?
        </p>
        <div className="alertPopup-buttons">
            <button
                className="alertPopup-button-cancel"
                onClick={() => toggleDeleteChatPopup()}
            >
                Avbryt
            </button>
            <button
                onClick={() => {
                    sendDeleteConversation(conversationId);
                    closeConversation(conversationId);
                    toggleDeleteChatPopup();
                }}
                className="alertPopup-button-confirm"
            >
                Ja, slett samtale
            </button>
        </div>
    </article>
);

export const AlertPopup = {
    Close,
    Delete
};
