import { useState, useEffect } from 'preact/hooks';

export const useToggle = outsideClickElement => {
    const [toggleValue, setToggleValue] = useState(false);

    const handleOutsideClick = () => {
        if (toggleValue) {
            setToggleValue(false);
        }
    };

    const toggle = () => {
        setToggleValue(s => !s);
    };

    useEffect(() => {
        const element = outsideClickElement || document.body;

        element.addEventListener('click', handleOutsideClick);

        return () => {
            element.removeEventListener('click', handleOutsideClick);
        };
    });

    return [toggleValue, toggle];
};
