import { h } from 'preact'; /** @jsx h */

import './AmkHeader.css';
import { useToggle } from '../utils/useToggle';

const AmkHeader = ({ sendLogOut, onLoggedOut }) => {
    const [isExpanded, toggleExpanded] = useToggle();

    return (
        <header>
            <img
                height="36"
                src="/img/logo_with_text.svg"
                alt="Stiftelsen Norsk Luftambulanse"
            />
            <div className="header__expandable">
                <button
                    className="header__button header__button--expandable"
                    onClick={toggleExpanded}
                >
                    Bodø AMK
                </button>

                {isExpanded && (
                    <button
                        className="header__button header__button--action"
                        onClick={() => {
                            sendLogOut();
                            onLoggedOut();
                        }}
                    >
                        Logg ut
                    </button>
                )}
            </div>
        </header>
    );
};

export default AmkHeader;
