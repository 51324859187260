import { h } from 'preact'; /** @jsx h */
import { useState, useEffect } from 'preact/hooks';
import { fetchCallerInfo } from '../utils/callerInfoApi';
import { RemoteDataStatus } from '../utils/remoteData';

const formatBirthDate = birthDate =>
    birthDate
        .split('-')
        .reverse()
        .join('.');

const getBirthDateText = maybeBirthDate =>
    maybeBirthDate !== '' ? `Født ${formatBirthDate(maybeBirthDate)}` : '';

const calculateAge = maybeBirthDate => {
    const birthDate = new Date(maybeBirthDate);

    const age = Math.floor(
        (Date.now() - birthDate) / (24 * 3600 * 365.25 * 1000)
    );

    return age;
};

const CallerNumberInfo = ({ phoneNumber, isExpanded }) => {
    const [status, setStatus] = useState(RemoteDataStatus.Loading);
    const [data, setData] = useState({});
    const [error, setError] = useState(
        'Noe gikk galt med henting av data om nummeret'
    );

    useEffect(() => {
        if (isExpanded) {
            fetchCallerInfo(phoneNumber, params => {
                setStatus(params.status);

                if (params.status === RemoteDataStatus.Success) {
                    setData(params.data);
                }

                if (params.status === RemoteDataStatus.Failure) {
                    setError(
                        params.remoteDataError.reason || params.remoteDataError
                    );
                }
            });
        }
    }, [isExpanded]);

    if (!isExpanded) {
        return;
    }

    switch (status) {
        case RemoteDataStatus.Loading:
            return (
                <div
                    className="callerInfo-details"
                    onClick={event => event.stopPropagation()}
                >
                    <div className="callerInfo-details-header">
                        Slår opp data om nummeret
                    </div>
                </div>
            );
        case RemoteDataStatus.Failure:
            return (
                <div
                    className="callerInfo-details"
                    onClick={event => event.stopPropagation()}
                >
                    <div className="callerInfo-details-header">{error}</div>
                </div>
            );
        case RemoteDataStatus.Success: {
            const { fullName, address, postalCode, city, birthDate } = data;

            return (
                <div
                    className="callerInfo-details"
                    onClick={event => event.stopPropagation()}
                >
                    <div className="callerInfo-details-header">
                        Nummer registrert på
                    </div>
                    <div className="callerInfo-details-name">
                        {fullName || 'Ukjent navn'}
                    </div>
                    <div className="callerInfo-details-street">
                        {address || 'Ukjent adresse'}
                    </div>
                    <div className="callerInfo-details-city">{`${postalCode ||
                        'Ukjent postnummer'} ${city || 'Ukjent by'}`}</div>
                    <br />
                    {birthDate && (
                        <div className="callerInfo-details-birthDate">
                            {getBirthDateText(birthDate)}
                            {' (' + calculateAge(birthDate) + ' år)'}
                        </div>
                    )}
                </div>
            );
        }
    }
};

export default CallerNumberInfo;
