/* global sessionStorage */
import { useState } from 'preact/hooks';

const tokenKey = window.IS_TEST ? 'AMK-APP-TEST' : 'AMK-APP';
const tokenExpirationTimeKey = 'TOKEN-EXPIRATION-TIME';

const initState = {
    token: sessionStorage.getItem(tokenKey),
    tokenExpirationTime: sessionStorage.getItem(tokenExpirationTimeKey)
};

export const useStorage = () => {
    const [storageState, setStorageState] = useState(initState);

    const updateStorage = ({ token, tokenExpirationTime }) => {
        setStorageState({ token, tokenExpirationTime });
        sessionStorage.setItem(tokenKey, token);
        sessionStorage.setItem(tokenExpirationTimeKey, tokenExpirationTime);
    };

    const clearStorage = () => {
        setStorageState({});
        sessionStorage.clear();
    };

    return [storageState, updateStorage, clearStorage];
};

export const getToken = () => sessionStorage.getItem(tokenKey);
