import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

import { Illustration } from '../Illustrations.js';
import { Icon } from '../Icons';
import { AmkLoginPhoneNumber, AmkLoginCode } from './login/LoginForm';

import './AmkLogin.css';

const AmkLogin = ({ onLoggedIn }) => {
    const [view, setView] = useState('phoneNumber');

    return (
        <div className="login">
            <div className="login-logo">
                <Icon.Logo />
                <h3 className="login-text">Logg inn</h3>
            </div>
            {view === 'phoneNumber' ? (
                <AmkLoginPhoneNumber onSuccess={() => setView('code')} />
            ) : (
                <AmkLoginCode onSuccess={onLoggedIn} />
            )}
            <div className="login-illustration">
                <Illustration.Nla />
            </div>
        </div>
    );
};

export default AmkLogin;
