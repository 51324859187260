import { h } from 'preact'; /** @jsx h */
import { useState, useEffect, useRef } from 'preact/hooks';
import { defaultQuestions } from '../utils/defaultQuestions';
import './ChatForm.css';

const ChatForm = ({ send, sendTyping, locationData }) => {
    const [text, setText] = useState('');
    const [isActive, setActive] = useState(false);

    const textAreaRef = useRef();
    const textAreaInitialHeight = 88;

    const onTextInput = ({ target }) => {
        setText(t => target.value);
        sendTyping();
    };

    const formSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        if (text !== '') {
            send(text);
            setText(t => '');

            textAreaRef.current.style.height = 'inherit';
        }

        textAreaRef.current.focus();
    };

    useEffect(() => {
        document.addEventListener('input', event => {
            if (event.target.tagName.toLowerCase() === 'textarea') {
                autoExpand(event.target);
            }
        });
    });

    const autoExpand = field => {
        field.style.height = 'inherit';

        if (field.scrollHeight > textAreaInitialHeight) {
            const height = field.scrollHeight;

            field.style.height = height + 'px';
        }
    };

    const inputQuestion = (question, event) => {
        setText(t => question[1]);
        event.target.style.display = 'none';
        textAreaRef.current.focus();
    };

    return (
        <form
            method="post"
            onSubmit={formSubmit}
            className={`chatForm ${isActive ? 'chatForm--active' : ''}`}
        >
            <textarea
                ref={textAreaRef}
                rows={4}
                value={text}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                className="chatForm-input"
                onInput={onTextInput}
                placeholder="Skriv melding..."
            />
            <div className="chatForm-questionList-overlay">
                <ul className="chatForm-questionsList" tabIndex={-1}>
                    {defaultQuestions(locationData).map((question, index) => (
                        <li key={index}>
                            <button
                                className="chatForm-questionsList-item"
                                type="button"
                                onClick={event => {
                                    inputQuestion(question, event);
                                }}
                            >
                                {`${index + 1}. ${question[0]}`}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <button
                disabled={text === ''}
                className="chatForm-button"
                type="submit"
            >
                Send
            </button>
        </form>
    );
};

export default ChatForm;
