import { h } from 'preact'; /** @jsx h */
import { useState, useEffect } from 'preact/hooks';
import * as clipboard from 'clipboard-polyfill';

import './AmkChatView.css';

import Chat from '../ChatComponents/Chat';
import ChatForm from '../ChatComponents/ChatForm';
import CallerInfo from './CallerInfo';
import ClosedCallerInfo from './ClosedCallerInfo';
import LocationMessage from './LocationMessage';
import { Icon } from '../Icons';
import { ConversationStatus } from '../utils/conversationStatus';
import { calculateDurationToText } from '../utils/useDuration';

const ConversationStatusBar = ({
    numberOfConversations,
    selectConversation
}) => (
    <div className="chat-conversationStatusBar">
        <button onClick={() => selectConversation()}>
            <Icon.BackArrow />
            Se alle aktive samtaler
        </button>
        <p>{numberOfConversations} samtale(r)</p>
    </div>
);

const ChatStatusBar = ({ status }) => {
    const statusAnimationClass =
        Date.now() - status.timestamp.getTime() < 4000
            ? 'chat-status--animate'
            : '';

    if (status.status === ConversationStatus.Reconnecting) {
        return (
            <div className={`chat-statusError ${statusAnimationClass}`}>
                Bruker mistet internett-tilkobling
            </div>
        );
    }

    if (status.status === ConversationStatus.Active) {
        return (
            <div className={`chat-statusSuccess ${statusAnimationClass}`}>
                Tilkobling gjenopprettet
            </div>
        );
    }

    return null;
};

const checkConversationActive = conversation =>
    !conversation.statusList.some(s => s.status === ConversationStatus.Closed);

const generateConversationString = messages => {
    const dateTimeOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    let conversationString = ``;

    messages.forEach(message => {
        const messageString = `${new Date(message.timestamp).toLocaleDateString(
            'nb-NO',
            dateTimeOptions
        )} --- Melding fra ${message.sender}: "${message.text}" \n\n`;
        conversationString += messageString;
    });

    return conversationString;
};

const ClosedConversationOptions = ({ conversation, onClick }) => {
    const [isCopied, toggleCopyState] = useState(false);

    const { startTime, endTime } = conversation;
    const duration = calculateDurationToText(startTime, endTime);
    const dateTimeOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const messages = conversation
        ? conversation.messages.map(m => ({
              sender: m.from || conversation.phoneNumber,
              text: m.content,
              timestamp: m.timestamp
          }))
        : [];

    useEffect(() => {
        let timeout;
        if (isCopied) {
            timeout = setTimeout(() => toggleCopyState(false), 1000);
        }
        return () => clearTimeout(timeout);
    }, [isCopied]);

    return (
        <div>
            <div className="closedChat-endingInfo-wrapper">
                <span className="closedChat-endingInfo-text">
                    SAMTALE AVSLUTTET
                </span>
                <span className="closedChat-endingInfo-time">
                    {new Date(endTime).toLocaleDateString(
                        'nb-NO',
                        dateTimeOptions
                    ) +
                        ' (' +
                        duration +
                        ')'}
                </span>
            </div>
            <div className="closedChat-buttons">
                {!isCopied ? (
                    <button
                        onClick={() => {
                            clipboard.writeText(
                                generateConversationString(messages)
                            );
                            toggleCopyState(true);
                        }}
                        className="closedChat-button-copy"
                    >
                        Kopier samtale
                    </button>
                ) : (
                    <button className="closedChat-button--copied">
                        Kopiert
                    </button>
                )}
                <button onClick={onClick} className="closedChat-button-delete">
                    Slett samtale
                </button>
            </div>
        </div>
    );
};

const AmkChatView = ({
    send,
    sendTyping,
    conversation,
    activeConversationPhoneNumber,
    numberOfConversations,
    selectConversation,
    closeConversation,
    toggleCloseChatPopup,
    toggleDeleteChatPopup
}) => {
    const chatStatus =
        conversation &&
        conversation.statusList &&
        conversation.statusList.length > 0
            ? conversation.statusList[conversation.statusList.length - 1]
            : null;

    return (
        activeConversationPhoneNumber &&
        conversation &&
        (checkConversationActive(conversation) ? (
            <div className="chat">
                <ConversationStatusBar
                    numberOfConversations={numberOfConversations}
                    selectConversation={selectConversation}
                />
                <CallerInfo
                    phoneNumber={activeConversationPhoneNumber}
                    name={conversation.name}
                    callerInfo={conversation.callerInfo}
                    startTime={conversation.startTime}
                    toggleCloseChatPopup={toggleCloseChatPopup}
                />
                <LocationMessage
                    location={conversation.location}
                    address={conversation.address}
                />
                <Chat
                    activeConversation={activeConversationPhoneNumber}
                    userId="amk"
                    otherIsTyping={conversation.isTyping}
                    messages={
                        conversation !== undefined
                            ? conversation.messages.map(m => ({
                                  sender: m.from || conversation.phoneNumber,
                                  text: m.content,
                                  timestamp: m.timestamp
                              }))
                            : []
                    }
                    getMessageAvatar={isUser =>
                        isUser ? <Icon.AmkAvatarForAmk /> : null
                    }
                />
                {chatStatus && <ChatStatusBar status={chatStatus} />}
                <ChatForm
                    key={activeConversationPhoneNumber}
                    send={send(conversation.id)}
                    sendTyping={sendTyping(conversation.id)}
                    locationData={
                        conversation.address.data
                            ? conversation.address.data.addressComponents[0]
                                  .long_name
                            : ''
                    }
                />
            </div>
        ) : (
            <div className="chat">
                <ConversationStatusBar
                    numberOfConversations={numberOfConversations}
                    selectConversation={selectConversation}
                />
                <ClosedCallerInfo
                    phoneNumber={activeConversationPhoneNumber}
                    name={conversation.name}
                    callerInfo={conversation.callerInfo}
                    startTime={conversation.startTime}
                    endTime={conversation.endTime}
                    closeConversation={closeConversation}
                    conversationId={conversation.id}
                />
                <LocationMessage
                    location={conversation.location}
                    address={conversation.address}
                />
                <Chat
                    userId="amk"
                    endTime={conversation.endTime}
                    startTime={conversation.startTime}
                    messages={
                        conversation !== undefined
                            ? conversation.messages.map(m => ({
                                  sender: m.from,
                                  text: m.content,
                                  timestamp: m.timestamp
                              }))
                            : []
                    }
                    getMessageAvatar={isUser =>
                        isUser ? <Icon.AmkAvatarForAmk /> : null
                    }
                />
                <ClosedConversationOptions
                    conversation={conversation}
                    onClick={toggleDeleteChatPopup}
                />
            </div>
        ))
    );
};

export default AmkChatView;
