/* globals WebSocket */
import { useState, useEffect } from 'preact/hooks';
import { notAsked } from './remoteData';
import { fetchGeocodingInfo } from './geocodingApi';
import { ConversationStatus } from './conversationStatus';
import {
    SocketStatus,
    mapSocketReadyStateToSocketStatus
} from './socketStatus';

const WS_URL = process.env.WS_CHAT;

const MessageTypes = {
    NewAmkConnection: 'new-amk-connection',
    NewAppConnection: 'new-app-connection',
    NewAppMessage: 'new-app-message',
    NewAmkMessage: 'new-amk-message',
    AmkTyping: 'amk-typing',
    AppTyping: 'app-typing',
    UpdatePhoneList: 'update-phonelist',
    AppLocation: 'app-location',
    OpenedByAmk: 'opened-by-amk',
    AmkDisconnect: 'amk-disconnect',
    StatusChange: 'status-change',
    AmkEndConversation: 'amk-end-conversation',
    RequestChatHistory: 'request-chat-history',
    AnswerChatHistory: 'answer-chat-history',
    DeleteConversation: 'delete-conversation',
    ConversationInActive: 'closed-by-amk'
};

const mergeMessageLists = conversation => [
    ...conversation.amkMessages.map(m => ({ ...m, from: 'amk' })),
    ...conversation.appMessages.map(m => ({
        ...m,
        from: 'app'
    }))
];

const useAmkSocket = token => {
    const [socket, setSocket] = useState(null);
    const [state, setState] = useState({
        conversations: [],
        closedConversations: [],
        activeConversationPhoneNumber: null,
        ready: false,
        status: SocketStatus.NotStarted,
        playSound: false
    });

    const updateConversation = (conversationId, updateFn) =>
        setState(s => ({
            ...s,
            conversations: s.conversations.map(c =>
                c.id === conversationId ? updateFn(c) : c
            )
        }));

    const togglePlaySound = playSound => setState(s => ({ ...s, playSound }));

    const connect = token => {
        setSocket(new WebSocket(WS_URL, token));
        setState(s => ({
            ...s,
            status: SocketStatus.Connecting
        }));
    };

    const handleOpen = () => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.NewAmkConnection
            })
        );
        setState(s => ({
            ...s,
            status: SocketStatus.Open
        }));
    };

    const selectConversation = conversationId => {
        setState(s => {
            if (s.activeConversationId) {
                sendInActive(s.activeConversationId);
            }

            return {
                ...s,
                activeConversationId: conversationId
            };
        });

        if (conversationId) {
            sendOpened(conversationId);
        } else {
            sendInActive(state.activeConversationId);
        }
    };

    const selectClosedConversation = conversationId => {
        setState(s => ({
            ...s,
            activeConversationId: conversationId
        }));
    };

    const closeConversation = conversationId => {
        setState(s => ({
            ...s,
            activeConversationId: '',
            conversations: s.conversations.filter(c => c.id !== conversationId)
        }));
    };

    const newAppMessage = (conversationId, content, timestamp) => {
        updateConversation(conversationId, c => ({
            ...c,
            messages: [...c.messages, { content, timestamp }],
            isTyping: false
        }));
        togglePlaySound(true);
    };

    const newAmkMessage = (conversationId, content, timestamp) =>
        updateConversation(conversationId, c => ({
            ...c,
            messages: [...c.messages, { from: 'amk', content, timestamp }],
            isTyping: false
        }));

    const resetTypingState = conversationId =>
        updateConversation(conversationId, c => ({
            ...c,
            isTyping: false,
            typingTimeout: null
        }));

    const newAppTyping = message =>
        updateConversation(message.conversationId, c => {
            const prevTimeout = c.typingTimeout;

            if (prevTimeout) {
                clearTimeout(prevTimeout);
            }

            return {
                ...c,
                isTyping: true,
                typingTimeout: setTimeout(
                    () => resetTypingState(message.conversationId),
                    1000
                )
            };
        });

    const fetchAndUpdateAddress = (conversationId, location) => {
        fetchGeocodingInfo(location, remoteData =>
            updateConversation(conversationId, c => ({
                ...c,
                address: remoteData
            }))
        );
    };

    const newAppConnection = (conversationId, phoneNumber, name, startTime) => {
        setState(s => ({
            ...s,
            conversations: [
                ...s.conversations,
                {
                    id: conversationId,
                    phoneNumber,
                    name,
                    messages: [],
                    isTyping: false,
                    callerInfo: notAsked(),
                    address: notAsked(),
                    startTime: new Date(startTime),
                    statusList: [
                        {
                            status: ConversationStatus.Waiting,
                            timestamp: new Date()
                        }
                    ]
                }
            ]
        }));

        togglePlaySound(true);
    };

    const updateConversationStatus = (phoneNumber, newStatus, table) =>
        updateConversation(phoneNumber, c => ({
            ...c,
            endTime:
                c.endTime || newStatus === ConversationStatus.Closed
                    ? new Date()
                    : null,
            statusList: [
                ...c.statusList,
                {
                    status: newStatus,
                    timestamp: new Date(),
                    table
                }
            ]
        }));

    const mapStatusList = (statusList, status, timestamp) => {
        if (statusList) {
            return statusList.map(({ status, table, timestamp }) => ({
                status,
                table,
                timestamp: new Date(timestamp)
            }));
        } else {
            return {
                status,
                timestamp: new Date(timestamp)
            };
        }
    };

    const initConvosForNewAmk = conversations =>
        conversations.forEach(conversation => {
            setState(s => ({
                ...s,
                conversations: [
                    ...s.conversations,
                    {
                        id: conversation.id,
                        phoneNumber: conversation.phoneNumber,
                        name: conversation.name,
                        messages: mergeMessageLists(conversation),
                        location: conversation.location,
                        isTyping: false,
                        callerInfo: notAsked(),
                        address: notAsked(),
                        startTime: new Date(conversation.startTime),
                        statusList: mapStatusList(
                            conversation.statusList,
                            conversation.status,
                            conversation.statusTimestamp
                        )
                    }
                ]
            }));

            fetchAndUpdateAddress(conversation.id, conversation.location);
        });

    const updateAppLocation = (conversationId, location) => {
        updateConversation(conversationId, c => ({
            ...c,
            location
        }));

        fetchAndUpdateAddress(conversationId, location);
    };

    const getClosedChatHistory = closedConversations => {
        closedConversations.forEach(closedConversation => {
            setState(s => ({
                ...s,
                closedConversations: [
                    ...s.closedConversations,
                    {
                        id: closedConversation.id,
                        phoneNumber: closedConversation.phoneNumber,
                        name: closedConversation.name,
                        messages: mergeMessageLists(closedConversation),
                        location: closedConversation.location,
                        isTyping: false,
                        callerInfo: notAsked(),
                        address: notAsked(),
                        startTime: new Date(closedConversation.startTime),
                        endTime: new Date(closedConversation.statusTimestamp),
                        statusList: [
                            {
                                status: closedConversation.status,
                                timestamp: new Date(
                                    closedConversation.statusTimestamp
                                )
                            }
                        ]
                    }
                ].sort((a, b) => (a.endTime < b.endTime ? 1 : -1))
            }));
        });
    };

    const handleMessage = event => {
        const serverMessage = JSON.parse(event.data);

        switch (serverMessage.type) {
            case MessageTypes.NewAmkConnection:
                break;

            case MessageTypes.NewAppConnection:
                newAppConnection(
                    serverMessage.conversationId,
                    serverMessage.phoneNumber,
                    serverMessage.name,
                    serverMessage.startTime
                );

                break;

            case MessageTypes.NewAppMessage:
                newAppMessage(
                    serverMessage.conversationId,
                    serverMessage.content,
                    serverMessage.timestamp
                );

                break;

            case MessageTypes.NewAmkMessage:
                newAmkMessage(
                    serverMessage.conversationId,
                    serverMessage.content,
                    serverMessage.timestamp
                );

                break;

            case MessageTypes.AppTyping:
                newAppTyping(serverMessage);
                break;

            case MessageTypes.UpdatePhoneList:
                initConvosForNewAmk(serverMessage.conversations);
                break;

            case MessageTypes.AppLocation:
                updateAppLocation(
                    serverMessage.conversationId,
                    serverMessage.location
                );
                break;

            case MessageTypes.StatusChange:
                updateConversationStatus(
                    serverMessage.conversationId,
                    serverMessage.status,
                    serverMessage.table
                );
                break;

            case MessageTypes.AnswerChatHistory:
                getClosedChatHistory(serverMessage.closedConversations);
                break;

            default:
                throw new Error(`Unknown message type ${serverMessage.type}`);
        }
    };

    const handleError = event => {
        setState(s => ({
            ...s,
            status: SocketStatus.Error
        }));
    };

    useEffect(() => {
        if (socket) {
            const status =
                state.status !== SocketStatus.Error
                    ? mapSocketReadyStateToSocketStatus(socket.readyState)
                    : state.status;

            if (status === SocketStatus.Open && status !== state.status) {
                handleOpen();
            }

            socket.addEventListener('open', handleOpen);
            socket.addEventListener('message', handleMessage);
            socket.addEventListener('error', handleError);
        }

        return () => {
            if (socket) {
                socket.removeEventListener('open', handleOpen);
                socket.removeEventListener('message', handleMessage);
                socket.removeEventListener('error', handleError);
            }
        };
    }, [socket]);

    const send = conversationId => content => {
        if (conversationId !== null) {
            socket.send(
                JSON.stringify({
                    type: MessageTypes.NewAmkMessage,
                    conversationId,
                    content
                })
            );
        }
    };

    const sendTyping = conversationId => () => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.AmkTyping,
                conversationId
            })
        );
    };

    const sendOpened = conversationId => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.OpenedByAmk,
                conversationId
            })
        );
    };

    const sendInActive = conversationId => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.ConversationInActive,
                conversationId
            })
        );
    };

    const sendLogOut = () => {
        socket.send(
            JSON.stringify({
                from: 'amk',
                type: MessageTypes.AmkDisconnect
            })
        );
    };

    const sendEndChat = conversationId => () => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.AmkEndConversation,
                conversationId
            })
        );
    };

    const sendRequestChatHistory = (fn, lastClosedConversationId) => {
        socket.send(
            JSON.stringify({
                type: MessageTypes.RequestChatHistory,
                lastClosedConversationId
            })
        );
    };

    const sendDeleteConversation = conversationId => {
        setState(s => ({
            ...s,
            closedConversations: []
        }));

        socket.send(
            JSON.stringify({
                type: MessageTypes.DeleteConversation,
                conversationId: conversationId
            })
        );
    };

    if (token != null && state.status === SocketStatus.NotStarted) {
        connect(token);
    }

    return {
        connect,
        state,
        send,
        sendTyping,
        sendOpened,
        selectConversation,
        closeConversation,
        sendLogOut,
        sendEndChat,
        togglePlaySound,
        sendRequestChatHistory,
        selectClosedConversation,
        sendDeleteConversation
    };
};

export default useAmkSocket;
