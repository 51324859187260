import { h } from 'preact'; /** @jsx h */
import { useToggle } from '../utils/useToggle';
import { useDuration } from '../utils/useDuration';
import ShortInfo from './ShortInfo';
import CallerNumberInfo from './CallerNumberInfo';

import './CallerInfo.css';

const CallerInfo = ({ phoneNumber, name, startTime, toggleCloseChatPopup }) => {
    const { duration } = useDuration(startTime);
    const [isExpanded, toggleExpanded] = useToggle();

    return (
        <div className="callerInfo">
            <div className="callerInfo-shortInfo-wrapper">
                <ShortInfo
                    name={name}
                    phoneNumber={phoneNumber}
                    onClick={event => {
                        toggleExpanded();
                        event.stopPropagation();
                    }}
                />
                <div className="callerInfo-shortInfo-conversationInfo">
                    <button
                        className="callerInfo-shortInfo-conversationInfo-button"
                        onClick={event => {
                            toggleCloseChatPopup();
                            event.stopPropagation();
                        }}
                    >
                        Avslutt samtale
                    </button>
                    <div className="callerInfo-shortInfo-conversationInfo-time">
                        {duration}
                    </div>
                </div>
            </div>

            <CallerNumberInfo
                isExpanded={isExpanded}
                phoneNumber={phoneNumber}
            />
        </div>
    );
};

export default CallerInfo;
