import { h } from 'preact'; /** @jsx h */
import './Illustrations.css';

const Nla = () => (
    <svg
        className="nla"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 600 107"
        style="enable-background:new 0 0 600 107;"
    >
        <g>
            <polygon points="174.1,78.5 163.4,78.5 150.2,65.2 149,66.3 162.8,80.1 174.1,80.1 	" />
            <path
                d="M494.8,105.3V80.7c0-0.2,0-4.3-3.2-6.2l-10.5-6.2l3.6-2.1c5.1-2.9,7.2-6.2,7.2-11.2c0-6.8-5.6-12.4-12.4-12.4
		s-12.4,5.6-12.4,12.4c0,5,2,8.2,7.2,11.2l3.6,2.1l-10.5,6.2c-3.2,1.9-3.2,6.1-3.2,6.2v24.6h-19.9l-51.9-0.1h-12.2l0.2-35.6
		c8-1.5,14-8.6,14-16.8c0-9.5-7.7-17.2-17.1-17.2c-1.8,0-3.6,0.3-5.3,0.8V25.8L347,0l-24.7,25.6l-0.3,0.3v29.9l-4.7-4.9l-16,16.6
		v37.9H289h-1.6H161.6c1.6-1.4,2.6-3.3,3-5.4h18.5h0.1V79.5l-24.4-25l-3.7-0.1c0-0.5,0-1.1,0-1.2c0-1.6-1.3-3-2.8-3
		c-1.6,0-2.8,1.2-2.8,2.8v0.3c0,0.3,0,0.6,0,1l-3.5,0.1l-4.6-4.5l-15.9,0.1v9.8h8.2v5.4h-8.2v8.8h-5.4v-8.8h-8.8v-5.4h8.8v-9.9H88
		v46.4l2.6,2.8H101c0.2,2.4,1.3,4.5,3,6.1H-79.3v1.6H88.6h17.5v-2.1l-0.3-0.2c-2-1.4-3.2-3.6-3.2-6.1c0-4.1,3.3-7.4,7.3-7.4
		c4,0,7.3,3.3,7.3,7.4c0,2.4-1.1,4.7-3.1,6.1l-0.3,0.2v2.1h38l-0.1-2.2l-0.3-0.2c-2-1.4-3.2-3.6-3.2-6.1c0-4.1,3.3-7.4,7.3-7.4
		c4,0,7.3,3.3,7.3,7.4c0,2.4-1.1,4.7-3.1,6.1l-0.3,0.2v2.1h142.3h1V68.1l14.4-14.9l8.8,9.1l-4.8,5.2v38h1.6V68.1l13.9-14.9
		l13.8,14.9v37.3h1.6v-38l-15.4-16.6l-9.6,10.3l-3.6-3.7v-31L347,2.3l23.4,24.2v10.6c-6,2.7-10.2,8.7-10.2,15.7c0,3.5,1,6.8,3,9.6
		l1.3-0.9c-1.7-2.5-2.6-5.6-2.6-8.7c0-8.6,7-15.5,15.5-15.5s15.5,7,15.5,15.5c0,7.7-5.7,14.3-13.3,15.4l-0.7,0.1l-0.2,38.6h13.8
		l51.9,0.1h21.5V80.7c0,0,0-3.4,2.4-4.8l11.3-6.6l11.2,6.6c2.5,1.5,2.5,4.8,2.5,4.8V107h153.7v-1.7H494.8z M149.9,105.4H116
		c1.7-1.6,2.8-3.7,3-6h22.1h0.1h5.7C147,101.7,148.1,103.9,149.9,105.4z M155.7,89.6c-4.6,0-8.5,3.6-8.9,8.2h-5.7h-0.1H119
		c-0.4-4.6-4.4-8.2-9.1-8.2c-4.6,0-8.5,3.6-8.9,8.1h-9.8l-1.7-1.8V51.7h29v6.6h-8.8V67h8.8v8.8h8.6V67h8.2v-8.7h-8.2v-6.6l13.5-0.1
		l4.5,4.5l0.4,0.1l5.4-0.1v-0.8c0-0.4,0-1.4,0-1.8v-0.3c0-0.6,0.5-1.2,1.2-1.2c0.6,0,1.2,0.6,1.2,1.4c0,0.1,0,2,0,2v0.8l4.6,0.1
		l23.5,24v18.1h-16.8C164.6,93.5,160.6,89.6,155.7,89.6z M479.6,44.3c5.3,0,9.7,3.9,10.5,9h-12.3l-2.4-2.4l-6.5,3.8
		C469.1,48.9,473.8,44.3,479.6,44.3z M475.2,64.8c-4.6-2.6-6-5.3-6.3-8.3l6.2-3.6l2.1,2h13.1c0,0.1,0,0.1,0,0.2
		c0,3.6-1.1,6.7-6.4,9.7l-4.3,2.5L475.2,64.8z"
            />
            <rect x="336" y="21.4" width="1.6" height="7" />
            <rect x="346.8" y="21.4" width="1.6" height="7" />
            <rect x="357.5" y="21.4" width="1.6" height="7" />
            <rect x="336" y="36.4" width="1.6" height="7" />
            <rect x="346.8" y="36.4" width="1.6" height="7" />
            <rect x="357.5" y="36.4" width="1.6" height="7" />
            <rect x="332" y="71.2" width="1.6" height="7" />
            <rect x="340.4" y="71.2" width="1.6" height="7" />
            <rect x="340.4" y="85.2" width="1.6" height="7" />
            <rect x="312.4" y="71.2" width="1.6" height="7" />
            <rect x="312.4" y="85.2" width="1.6" height="7" />
        </g>
    </svg>
);

export const Illustration = { Nla };
