// [ShortQuestion (in button), LongQuestion (in textInput)]
const defaultQuestions = location => [
    [
        'Hvor er pasient?',
        ...(location
            ? [`Befinner pasienten seg i nærheten av ${location}?`]
            : [`Hvor er pasienten?`])
    ],
    ['Hva har skjedd?', 'Hjelpen er på vei, hva har skjedd?'],
    ['Er pasient våken?', 'Er pasienten våken?'],
    ['Kan pasient snakke?', 'Er pasienten i stand til å snakke?'],
    ['Reagerer pasient?', 'Reagerer pasient på tilrop og forsiktig risting?'],
    ['Puster pasient?', 'Puster pasient vanlig / normal?']
];

module.exports = { defaultQuestions };
