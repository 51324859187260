import { h, render, Fragment } from 'preact'; /** @jsx h */
import { useEffect } from 'preact/hooks';

import '../Index.css';
import './AmkApp.css';

import useAmkSocket from '../utils/useAmkSocket';
import ActiveConversations from './ActiveConversations';
import AmkChatView from './AmkChatView';
import AmkLogin from './AmkLogin';
import AmkHeader from './AmkHeader';
import ChatHistoryList from './ChatHistoryList';
import { AlertPopup } from './AlertPopup';

import { SocketStatus } from '../utils/socketStatus';
import { useStorage } from '../utils/useStorage';
import { useToggle } from '../utils/useToggle';

window.onGoogleMapsLoaded = () => {
    window.googleMapsReady = true;
};

const { MAPS_KEY, MAPS_URL } = window.CONFIG;

const loadGoogleMaps = () => {
    const url = `${MAPS_URL}js?key=${MAPS_KEY}&callback=onGoogleMapsLoaded`;

    const script = document.createElement('script');
    script.src = url;

    document.body.appendChild(script);
};

loadGoogleMaps();

const AmkApp = () => {
    const [chatHistoryIsOpen, toggleChatHistoryOpen] = useToggle(
        document.getElementById('amkApp')
    );
    const [closeChatPopup, toggleCloseChatPopup] = useToggle(
        document.getElementById('amkApp')
    );
    const [deleteChatPopup, toggleDeleteChatPopup] = useToggle(
        document.getElementById('amkApp')
    );

    const [
        { token, tokenExpirationTime },
        setTokenState,
        clearTokenState
    ] = useStorage();

    const {
        state,
        send,
        sendTyping,
        selectConversation,
        closeConversation,
        connect,
        sendLogOut,
        sendEndChat,
        togglePlaySound,
        sendRequestChatHistory,
        selectClosedConversation,
        sendDeleteConversation
    } = useAmkSocket(token);

    const isLoggedIn = !!token && tokenExpirationTime > Date.now();

    const conversation = [
        ...state.conversations,
        ...state.closedConversations
    ].find(c => c.id === state.activeConversationId);

    const onLoggedIn = (token, tokenExpirationTime) => {
        setTokenState({
            token,
            tokenExpirationTime
        });
        connect(token);
    };

    const onLoggedOut = () => {
        clearTokenState();
    };

    useEffect(() => {
        let timeout;
        if (state.playSound) {
            timeout = setTimeout(() => togglePlaySound(false), 3000);
        }
        return () => clearTimeout(timeout);
    }, [state.playSound]);

    useEffect(() => {
        if (tokenExpirationTime < Date.now()) {
            clearTokenState();
        }
    }, []);

    if (!isLoggedIn) {
        return <AmkLogin onLoggedIn={onLoggedIn} />;
    }

    switch (state.status) {
        case SocketStatus.NotStarted:
        case SocketStatus.Connecting:
            return (
                <div>
                    <h3>Kobler opp chat</h3>
                </div>
            );

        case SocketStatus.Open:
            return (
                <Fragment>
                    <main
                        id="amkApp"
                        className={`amkApp ${
                            chatHistoryIsOpen ? 'amkApp--historyOpen' : ''
                        } ${
                            closeChatPopup || deleteChatPopup
                                ? 'amkApp--popupOpen'
                                : ''
                        }`}
                    >
                        <AmkHeader
                            sendLogOut={sendLogOut}
                            onLoggedOut={onLoggedOut}
                        />
                        <div
                            className={`amkApp-content ${
                                !(state.activeConversationId && conversation)
                                    ? ''
                                    : 'amkApp--hideConversations'
                            }`}
                        >
                            <ActiveConversations
                                activeConversations={state.conversations}
                                activeConversationId={
                                    state.activeConversationId
                                }
                                activeConversationPhoneNumber={
                                    state.activeConversationPhoneNumber
                                }
                                selectConversation={selectConversation}
                                sendRequestChatHistory={sendRequestChatHistory}
                                toggleOpen={toggleChatHistoryOpen}
                                lastClosedConversationId={
                                    state.closedConversations[0] &&
                                    state.closedConversations[0].id
                                }
                            />
                            <AmkChatView
                                activeConversationPhoneNumber={
                                    conversation !== undefined &&
                                    conversation.phoneNumber
                                }
                                send={send}
                                sendTyping={sendTyping}
                                conversation={conversation}
                                messages={
                                    conversation !== undefined
                                        ? conversation.messages.sort(
                                              (a, b) =>
                                                  new Date(a.timestamp) >
                                                  new Date(b.timestamp)
                                          )
                                        : []
                                }
                                numberOfConversations={
                                    state.conversations.length
                                }
                                selectConversation={selectConversation}
                                closeConversation={closeConversation}
                                sendEndChat={sendEndChat}
                                sendDeleteConversation={sendDeleteConversation}
                                toggleCloseChatPopup={toggleCloseChatPopup}
                                toggleDeleteChatPopup={toggleDeleteChatPopup}
                            />
                        </div>
                    </main>
                    {chatHistoryIsOpen && (
                        <ChatHistoryList
                            closedConversations={state.closedConversations}
                            selectConversation={selectClosedConversation}
                            toggleOpen={toggleChatHistoryOpen}
                        />
                    )}

                    {deleteChatPopup && (
                        <AlertPopup.Delete
                            sendDeleteConversation={sendDeleteConversation}
                            closeConversation={closeConversation}
                            conversationId={conversation.id}
                            toggleDeleteChatPopup={toggleDeleteChatPopup}
                        />
                    )}
                    {closeChatPopup && (
                        <AlertPopup.Close
                            sendEndChat={sendEndChat(conversation.id)}
                            toggleCloseChatPopup={toggleCloseChatPopup}
                        />
                    )}

                    {state.playSound && (
                        <audio src="/sound/plingy.mp3" autoPlay hidden="" />
                    )}
                </Fragment>
            );
        case SocketStatus.Closing:
        case SocketStatus.Closed:
            return (
                <div>
                    <h3>Chatten er lukket</h3>
                </div>
            );

        case SocketStatus.Error:
        default:
            return (
                <div>
                    <h3>En feil har oppstått</h3>
                </div>
            );
    }
};

render(<AmkApp />, document.body);
