import { h } from 'preact'; /** @jsx h */
import { useState, useEffect } from 'preact/hooks';

import './ChatHistoryList.css';
import { Icon } from '../Icons';

const dateTimeOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};

const ChatHistoryListItem = ({
    phoneNumber,
    name,
    conversationId,
    selectConversation,
    endTime,
    toggleOpen
}) => {
    return (
        <li
            className="chatHistoryList-item"
            onClick={() => {
                selectConversation(conversationId);
                toggleOpen();
            }}
        >
            <div className="chatHistoryList-item-callerInfo">
                <span className="chatHistoryList-item-callerInfo-name">
                    {name}
                </span>
                <span className="chatHistoryList-item-callerInfo-phoneNumber">
                    {phoneNumber}
                </span>
            </div>

            <span className="chatHistoryList-item-dateInfo">
                {new Date(endTime)
                    .toLocaleDateString('nb-NO', dateTimeOptions)
                    .replace(',', ' ')}
                <Icon.BackArrow />
            </span>
        </li>
    );
};

const ChatHistoryList = ({
    closedConversations,
    selectConversation,
    toggleOpen
}) => {
    const [phoneQuery, setPhoneQuery] = useState('');
    const [
        displayedClosedConversations,
        setDisplayedClosedConversations
    ] = useState([]);

    const onTextInput = ({ target }) => {
        setPhoneQuery(target.value);
    };

    const search = () => {
        setDisplayedClosedConversations(
            closedConversations.filter(
                ({ phoneNumber }) =>
                    phoneQuery === '' || phoneNumber.indexOf(phoneQuery) === 0
            )
        );
    };

    useEffect(() => {
        search();
    }, [phoneQuery]);

    useEffect(() => {
        setDisplayedClosedConversations(closedConversations);
    }, [closedConversations]);

    return (
        <article className="chatHistoryList-wrapper">
            <button
                className="chatHistoryList-closeButton"
                onClick={toggleOpen}
            >
                <Icon.Close />
            </button>
            <label className="chatHistoryList-input-label">
                Søk etter samtale
                <Icon.Search />
                <input
                    className="chatHistoryList-input"
                    type="tel"
                    autoFocus
                    maxLength={8}
                    placeholder="Telefonnummer f.eks. 90000000"
                    onInput={onTextInput}
                />
            </label>
            <ul className="chatHistoryList-list">
                {displayedClosedConversations &&
                    displayedClosedConversations.map(
                        ({ phoneNumber, name, id, endTime }) => (
                            <ChatHistoryListItem
                                name={name}
                                phoneNumber={phoneNumber}
                                key={id}
                                conversationId={id}
                                selectConversation={selectConversation}
                                endTime={endTime}
                                toggleOpen={toggleOpen}
                            />
                        )
                    )}
            </ul>
        </article>
    );
};
export default ChatHistoryList;
