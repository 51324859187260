import { h } from 'preact'; /** @jsx h */
import { useToggle } from '../utils/useToggle';
import ShortInfo from './ShortInfo';
import CallerNumberInfo from './CallerNumberInfo';

import './CallerInfo.css';
import './ClosedCallerInfo.css';

const ClosedCallerInfo = ({
    name,
    phoneNumber,
    closeConversation,
    conversationId
}) => {
    const [isExpanded, toggleExpanded] = useToggle();

    return (
        <div
            onClick={event => {
                toggleExpanded();
                event.stopPropagation();
            }}
            className="callerInfo callerInfo--closed"
        >
            <div className="callerInfo-shortInfo-wrapper">
                <ShortInfo
                    name={name}
                    phoneNumber={phoneNumber}
                    onClick={event => {
                        toggleExpanded();
                        event.stopPropagation();
                    }}
                />
                <div className="callerInfo-shortInfo-conversationInfo">
                    <button
                        className="callerInfo-shortInfo-conversationInfo-button"
                        onClick={event => {
                            closeConversation(conversationId);
                            event.stopPropagation();
                        }}
                    >
                        Lukk samtale
                    </button>
                </div>
            </div>

            <CallerNumberInfo
                isExpanded={isExpanded}
                phoneNumber={phoneNumber}
            />
        </div>
    );
};

export default ClosedCallerInfo;
