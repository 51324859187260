import { h } from 'preact'; /** @jsx h */
import { useEffect, useRef } from 'preact/hooks';

import './Chat.css';

const Chat = ({
    userId,
    otherIsTyping,
    messages,
    getMessageAvatar,
    waiting,
    isMobile
}) => {
    const lastMessageRef = useRef();

    const getMessageRef = index =>
        index === messages.length - 1 ? lastMessageRef : undefined;

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages.length]);

    return (
        <ul className={`chat-list ${isMobile ? 'chat-list--mobile' : ''}`}>
            {messages.map(({ sender, text, timestamp }, index) => (
                <li
                    ref={getMessageRef(index)}
                    className={`chat-message ${
                        sender === userId ? 'chat-message--my-message' : ''
                    }`}
                    key={timestamp}
                >
                    <div
                        className={`chat-message-bubble ${
                            sender === userId ? 'chat-message-my-bubble' : ''
                        }`}
                    >
                        {text}
                    </div>
                    {getMessageAvatar(sender === userId)}
                </li>
            ))}
            {otherIsTyping ? (
                <li className="chat-message-bubble">Skriver...</li>
            ) : (
                ''
            )}
            {waiting ? (
                <li className="chat-message-bubble">Venter på AMK...</li>
            ) : (
                ''
            )}
        </ul>
    );
};

export default Chat;
