/* globals fetch */

const fetchResult = async (uri, data) => {
    let result;

    try {
        result = await fetch(uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    } catch (error) {
        return { error: 'Feil på serveren' };
    }

    if (result.ok) {
        let json;

        try {
            json = await result.json();
        } catch (error) {
            return { error: 'Feil ved JSON parse' };
        }

        if (json.success) {
            return json;
        } else {
            return {
                error: json.error
            };
        }
    }

    return { error: 'Feil på serveren' };
};

// returner et objekt som har error eller så er alt ok
export const sendPhoneNumber = phoneNumber => {
    return fetchResult('/sendphonenumber', { phoneNumber });
};

export const sendCode = async (code, table) => {
    const test = Boolean(window.CONFIG.IS_TEST);

    return fetchResult('/sendcode', { code, table, test });
};
