import { h } from 'preact'; /** @jsx h */

import orderby from 'lodash.orderby';
import findlast from 'lodash.findlast';

import './ActiveConversations.css';
import ConversationListItem from './ConversationListItem';

const isConversationClosed = statusList =>
    statusList[statusList.length - 1].status === 'closed';

const getActiveTables = statusList => {
    // statusList er en kronologisk liste med statuser for samtalen
    // looper igjennom statusene og setter aktive bord i et objekt med bordnummer som key
    const tableStatusActive = statusList.reduce((obj, { status, table }) => {
        if (status === 'active' && table !== undefined) {
            obj[table] = 'active';
        } else if (status === 'inactive') {
            delete obj[table];
        }

        return obj;
    }, {});

    return Object.keys(tableStatusActive);
};

const isNotMessageFromAmk = ({ from }) => from === 'app' || from === undefined;

const getUnreadMessages = (isClosed, numberOfTables, messages, statusList) => {
    // hvis samtalen ikke er lukket og det ikke er noen aktive bord som lytter
    // så kan jeg telle opp antall uleste meldinger
    if (!isClosed && numberOfTables === 0) {
        // finn dato for siste inactive status
        const lastInActive = findlast(statusList, s => s.status === 'inactive');

        // hvis status aldri har vært inactive er alle meldinger ulest
        if (!lastInActive) {
            return messages.filter(isNotMessageFromAmk).length;
        }

        // filterer ut alle meldinger etter den datoen
        const messagesAfter = messages.filter(
            ({ timestamp }) =>
                lastInActive.timestamp.getTime() <
                    new Date(timestamp).getTime() && isNotMessageFromAmk
        );

        return messagesAfter.length;
    }

    return 0;
};

const ActiveConversations = ({
    activeConversations,
    activeConversationId,
    selectConversation,
    sendRequestChatHistory,
    lastClosedConversationId,
    toggleOpen
}) => {
    const orderedByDate = orderby(activeConversations, ['startTime'], ['desc']);

    const orderedByStatus = orderby(orderedByDate, [
        c => isConversationClosed(c.statusList)
    ]);

    return (
        <div className="activeConversations">
            <div className="activeConversations-title">
                <div>Aktive samtaler</div>
                <div>Ny nødchat samtale</div>
            </div>
            <ul className="activeConversations-list">
                {orderedByStatus.map(
                    ({
                        id,
                        messages,
                        name,
                        phoneNumber,
                        startTime,
                        statusList
                    }) => {
                        const isClosed = isConversationClosed(statusList);
                        const tables = getActiveTables(statusList);

                        const unreadMessages = getUnreadMessages(
                            isClosed,
                            tables.length,
                            messages,
                            statusList
                        );

                        return (
                            <ConversationListItem
                                key={id}
                                id={id}
                                appPhoneNumber={phoneNumber}
                                activeConversationId={activeConversationId}
                                selectConversation={() =>
                                    selectConversation(id)
                                }
                                name={name}
                                startTime={startTime}
                                isClosed={isClosed}
                                tables={tables}
                                unreadMessages={unreadMessages}
                            />
                        );
                    }
                )}
            </ul>
            <button
                className="activeConversations-chatHistory-button"
                onClick={event => {
                    sendRequestChatHistory(
                        toggleOpen(),
                        lastClosedConversationId
                    );
                    event.stopPropagation();
                }}
            >
                Se samtalehistorikk
            </button>
        </div>
    );
};

export default ActiveConversations;
